/*** Company Page Styles ***/
.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.flex-col {
  flex-direction: column;
}

.p-15 {
  padding: 15px;
}

.w-50 {
  .apexcharts-xaxis-tick {
    display: none;
  }
  .apexcharts-xaxistooltip{
    display: none;
  }
  .apexcharts-tooltip-title{
    display: none;
  }
  .apexcharts-xaxis {
    display: none;
  }

  width: 50%;
}

.e-rate-block {
  display: flex;
  padding: 18px;
  width: 50%;
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */
  height: 201px;
  border-radius: 10px;
}

.e-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.e-rate {
  /* Header 24 */
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  /* white */
  color: #FFFFFF;
  white-space: nowrap;
}

.prices-performance {
  display: flex;
  justify-content: space-between;
  padding-right: 12px;
}

.price-in {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 46px;
  color: #FFFFFF;
}

.hi-lo-container {
  display: flex;
  justify-content: space-between;
}

.hi-lo {
  display: flex;
  flex-direction: column;
  padding-right: 10px;
}

.high-title {
  white-space: nowrap;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #BCBCBC;
}

.high-value {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}

.performance-in {
  font-family: "Circular Std";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  position: relative;
  /* line-height: 25px; */
  top: 10px;
}
.positive{
  color: #00DC0A;
}
.negative{
  color: #FA4646; 
}

.b-a-title {
  white-space: nowrap;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 25px;

  /* Light Grey */
  color: #BCBCBC;
}

.content-inner-wrapper {
  font-family: 'Inter';
  gap: 1px;
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 25px;
  color: #FFFFFF;

  a:nth-child(even) {
    background: rgba(110, 110, 112, 0.2) !important;
  }

  overflow: auto;
  display: flex;
  height: 123%;
  width: 109%;
  flex-direction: column;
}

.additional-info {
  /* Header 24 */

  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  padding-top: 5px;

  -webkit-scrollbar {
    display: none;
  }

  color: #FFFFFF;
}

.ohlc-row {
  font-family: 'Inter';
  gap: 1px;
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 25px;
  color: #FFFFFF;
  cursor: pointer;
  padding: 1px;
  display: flex;
  justify-content: start;
  line-height: 31px;
}

.left-side {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 25px;
  color: #BCBCBC;
}

.right-side {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: right;
  color: #BCBCBC;
}

.b-a-value {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  color: #FFFFFF;

}

.b-a-block {
  padding: 34px;
  height: 200px;
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  width: 18%;
  gap: 32px;
}

.additional-block {
  height: 200px;
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  width: 27%;
  padding: 17px 33px 33px 28px;
  gap: 12px;
}

.company-nav-header {
  padding: 16px 16px 8px 16px;
  font-family: "Circular Std";

  .company-nav-header-back-button {
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #aeaeb2;
    margin-bottom: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    width: auto;

    span {
      margin-right: 10px;
    }
  }
}

.company-container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 8px 16px;
  flex-direction: row;

  @media (max-width: 992px) {
    flex-direction: column;
  }

  .one-third-width {
    width: calc(100% / 3 - 4px);
  }

  .second-third-width {
    width: calc(100% / 3 * 2 - 4px);
  }

  .half-width {
    width: calc(50% - 10px);
  }

  .full-width {
    width: 100% !important;
  }

  .flex-start {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }

  .non-forex-page {
    .company-chart-wrapper {
      width: 100% !important;
    }
  }

  .company-chart-wrapper {
    width: calc(60% - 8px);

    @media (max-width: 1200px) {
      width: 100%;
    }
  }

  .company-news-wrapper {
    height: 100%;
    display: flex;
    gap: 26px;
    flex-direction: column;
    width: calc(40% - 8px);

    @media (max-width: 1200px) {
      width: 100%;
    }
  }

  .company-container-row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    @media (max-width: 992px) {
      margin-top: 8px;
    }
  }

  .main-chart-container {
    .component-section-title {
      font-family: "Circular Std";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.04em;
      padding: 8px 8px 4px 8px;
    }
  }
}

.ai-sentiment-only {
  margin-top: 40px;
}

.calendar-container {
  padding: 17px 28px 0px 28px;
  height: 566px;
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 10px;
}

.date-wrapper {
  padding-top: 7px;
  display: flex;
  justify-content: space-between;
}

.num-date {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 51px;
  color: #FFFFFF;
}

.mm-yy {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  text-transform: uppercase;
  padding-left: 15px;
}

.right-arrow {
  padding-left: 6px;
}

.arrow-wrapper {
  position: relative;
  top: 7px;
}

.arrow-cal {
  width: 35px;
}

.cal-view {
  cursor: pointer;
  margin-top: 13px;
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #6E6E70;
}

.cal-button-wrapper {
  position: relative;
  left: 15px;
}

.cal-wrapper {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 75%;

  .calender-data-row {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    height: 31px;
    padding-top: 7px;
  }

  div:nth-child(odd) {
    background: rgba(110, 110, 112, 0.2);
  }
}

.activity-container {
  padding: 10px 24px;
}

.activity-container {
  .tab-wrapper {
    display: flex;
    flex-direction: row;
    gap: 30px;

    .activity-tab {
      cursor: pointer;
      font-family: 'Circular Std';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      color: #FFFFFF;
    }

    .active-activity-tab {
      text-decoration-line: underline;

    }
  }
}

body.light .company-nav-header .company-nav-header-back-button {
  color: #000;
}