/*** AI Sentiment Styles ***/
.active-circle {
  background: linear-gradient(260deg,
      #00dc0a 10.12%,
      #f1cf54 50.04%,
      #dc0000 83.33%) !important;
}

.ai-gauge {
  height: 305px;
  width: 305px;
}

.nuetral {
  transform: rotate(0deg);
}

.buy {
  transform: rotate(66deg);
}

.sell {
  transform: rotate(294deg);
}

.union {
  width: 34.03px;
  height: 92px;
  position: relative;
  top: -39px;
  right: 169px;
}

.curr-sentiment {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  position: relative;
  top: -32%;
  color: #FFFFFF;
}

.sentiment-value {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  position: relative;
  top: -30%;
  /* Gradient Yellow */
}

.neutral-sentiment {
  background: linear-gradient(90deg, #FFB413 1.67%, #FFB413 100%, #F7BC51 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-transform: uppercase;
}

.buy-sentiment {
  background: linear-gradient(90deg, #00B208 1.67%, #4ADA51 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-transform: uppercase;
}

.sell-sentiment {
  background: linear-gradient(268.42deg, #FA4646 1.28%, #C71A0B 98.66%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-transform: uppercase;
}

.ai-sentiment-page {
  height: 391px;
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 10px;
  padding: 16px 15px 21px 15px;

  .ai-sentiment-title {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #FFFFFF;
    margin-bottom: 23px;

  }

  .ai-sentiment-time-slots {
    width: 100%;

    .ai-sentiment-time-slots-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: 10px;
    }

    .ai-sentiment-time-slot {
      width: 25%;
      text-align: center;
      font-style: normal;
      font-weight: 450;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.02em;
      border-right: 1px solid #c7c7cc;
      padding: 0 8px;
      text-transform: uppercase;
      font-family: 'Circular Std';
      font-style: normal;
      font-weight: 450;
      font-size: 20px;
      line-height: 25px;
      text-align: center;

      /* Light Grey */

      color: #BCBCBC;
      cursor: pointer;

      &.active {
        color: #00dc0a;
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        border-right: none;
        padding-right: 0;
      }
    }
  }

  .meter-chart {
    position: relative;
    top: 5%;
    left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loader-notification {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    color: #000000;
  }
}