.vertical-card {
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */
  background: #171717;
  border-radius: 10px;

  .apexcharts-grid-row {
    fill: none;
  }

  text.apexcharts-text>tspan {
    fill: #BCBCBC;
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 10px;
    line-height: 13px;
  }

  .card-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    background: #171717;
    border-radius: 0;
    border: none;
    padding: 18px 18px 0px 18px;

    .card-header-symbol {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 8px;

      .ticker {
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        letter-spacing: -0.04em;
        color: white;
        cursor: pointer;
        left: 0;
      }

      .favorite-active {
        color: #00dc0a;
        cursor: pointer;
      }

      .favorite-inactive {
        color: #c7c7cc;
        cursor: pointer;
      }
    }

    .card-header-price {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .card-header-space-between {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .percentage-red {
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        margin-left: 15px;
        color: #FA4646;
        position: relative;
        top: 5px;
      }

      .percentage-green {

        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        color: #00DC0A;
        margin-left: 15px;
        position: relative;
        top: 5px;
      }

      .price {
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 46px;
        color: #FFFFFF;
      }
    }

    .currency-strength-star-outline-icon {
      fill: #C7C7CC !important;
      color: #C7C7CC !important;
      cursor: pointer;
    }

    .currency-strength-view-more {
      font-family: 'Circular Std';
      font-style: normal;
      font-weight: 450;
      font-size: 13px;
      line-height: 20px;
      color: #6E6E70;

      & svg {
        fill: #C7C7CC !important;
        color: #C7C7CC !important;
      }

      &:hover {
        color: #00dc0a !important;

        & svg {
          fill: #00dc0a !important;
          color: #00dc0a !important;
        }
      }
    }
  }

  .splitter {
    margin: 16px 0;
    margin-left: 9px;
    width: 95%;
    height: 1px;
    background: #BCBCBC;
  }

  .card-info {
    padding: 0px 18px 0px 18px;

    .card-info-row-sentiment {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 10px;

      .card-info-label {
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #BCBCBC;
        text-transform: capitalize;
      }

      .card-info-value {
        display: flex;
        font-style: normal;
        font-weight: 450;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: -0.04em;
        color: #2f2e31;
      }

      .bullish {
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        display: flex;
        align-items: center;
        color: #00DC0A;
        text-transform: capitalize;
      }

      .very-bullish {

        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        display: flex;
        align-items: center;

        color: #00DC0A;
        text-transform: capitalize;
      }

      .neutral {
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        display: flex;
        align-items: center;

        color: #FFB413;
        text-transform: capitalize;
      }

      .bearish {
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        display: flex;
        align-items: center;
        color: #FA4646;
        text-transform: capitalize;
      }

      .very-bearish {
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        display: flex;
        align-items: center;

        color: #FA4646;
        text-transform: capitalize;
      }
    }

    .card-info-row {
      width: 100%;
      display: flex;
      padding-left: 13px;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 10px;

      .card-info-label {
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 20px;
        color: #BCBCBC;
        text-transform: capitalize;
      }

      .card-info-value {
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        color: #FFFFFF;
      }

      .bullish {
        color: rgb(0, 255, 0, 0.7);
        text-transform: capitalize;
      }

      .very-bullish {
        color: rgb(34, 139, 34, 1);
        text-transform: capitalize;
      }

      .neutral {
        color: rgb(0, 0, 0, 0.5);
        text-transform: capitalize;
      }

      .bearish {
        color: rgb(255, 0, 0, 0.7);
        text-transform: capitalize;
      }

      .very-bearish {
        color: rgb(255, 0, 0, 1);
        text-transform: capitalize;
      }
    }
  }

  .card-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0;
    padding-left: 51px;
    border: none;
    padding-right: 43px;
    padding-bottom: 26px;

    .price-info {
      font-family: 'Circular Std';
      font-style: normal;
      font-weight: 450;
      font-size: 16px;
      line-height: 20px;
      color: #BCBCBC;
      
    }
    .price-info-value {

      font-family: 'Circular Std';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      color: #FFFFFF;
      margin-top: 8px;
    }
  }
}

.vertical-card-list-mode {
  background: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  min-width: 1250px;
  gap: 20px;

  .currency-strength-header-currency .ticker {
    left: 0;
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.04em;
    color: #000000;
  }

  .currency-strength-header-rate {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.04em;
    color: #121416;
  }

  .currency-strength-header-change {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.04em;
    color: #8E8E93;
  }

  .currency-strength-header-ai-sentiment .card-info-value {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.04em;
  }

  .currency-strength-header-new-sentiment .card-info-value {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.04em;
  }

  .currency-strength-header-bid,
  .currency-strength-header-ask,
  .currency-strength-header-volume {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.04em;
    color: #121416;
  }
}